<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  },
  mounted() {
    if (sessionStorage.getItem('token')) {
      sessionStorage.removeItem('uid');
      sessionStorage.removeItem('examine_id');
    }

  },
  watch: {},
  computed: {},
  filters: {},
  components: {},
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-bottom: calc(constant(safe-area-inset-bottom));
  padding-bottom: calc(env(safe-area-inset-bottom));
}

* {
  cursor: default;
  box-sizing: border-box;
}


/*每个页面公共css */
@import "./assets/css/common.css";
</style>
