import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// websocker
import socketApi from '@/utils/webSocket.js';//找到封装的socket.js文件
Vue.prototype.$ws = socketApi;//将其挂在原型上，这样 $socketApi就在所有的 Vue 实例中可用了。
// 封装axios
import axios from 'axios'
Vue.prototype.$axios = axios
import qs from 'qs'
Vue.prototype.$qs = qs

import Vant from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Vant);
Vue.use(ElementUI);
//自动转换为rem
import 'amfe-flexible/index.js'

//导入全局请求
import request from '@/utils/request.js';
Vue.prototype.BASE_URL = request.BASE_URL
Vue.prototype.$request = request.request;
import * as utils from './utils/util'
Vue.prototype.$utils = utils;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
