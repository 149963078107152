
// 转换成年月日时间格式
const formatDateTime = (date) => {
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? ('0' + m) : m;
  var d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  return y + '-' + m + '-' + d
};

function openFullScreen2() {
  const loading = this.$loading({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });
  setTimeout(() => {
    loading.close();
  }, 2000);
};

// 防止处理多次点击
function noMultipleClicks(methods, info) {
  console.log(this.noClick,'222');
	// methods是需要点击后需要执行的函数， info是点击需要传的参数
	let that = this;
	if (that.noClick) {
		// 第一次点击
		that.noClick = false;
		if((info && info !== '') || info ==0) {
			// info是执行函数需要传的参数
			methods(info);
		} else {
			methods();
		}
	} else {
		console.log('阻止了重复点击');
		// 这里是重复点击的判断
	}
}

export {
  formatDateTime,
  openFullScreen2,
  noMultipleClicks
}